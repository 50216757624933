<template>
  <transition name="fade">
    <v-dialog
      v-model="dialog"
      max-width="300"
      class="rounded-0"
      persistent
      scrollable
    >
      <v-card
        v-if="news[selectedNews]"
        flat
        color="#fff"
        style="border: 5px solid #caad87"
      >
        <v-card-title
          class="d-flex justify-center align-center"
          style="height: 110px"
        >
          <CloseBtn
            style="position: absolute; right: 0; top: 0; margin-bottom: 10px"
            size="30"
            :callback="
              () => {
                dialog = false;
              }
            "
          />
          <div
            v-if="news[selectedNews]"
            class="text-stroke-sm text-h5 pt-8 text-center"
          >
            {{ news[selectedNews].title }}
          </div></v-card-title
        >
        <v-card-text style="height: 280px">
          <div class="d-flex justify-center py-2">
            <div>
              <v-img
                v-if="news[selectedNews].imageUrl"
                :src="news[selectedNews].imageUrl"
                max-width="150"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-else
                :src="require('@/assets/explore/news.png')"
                max-width="150"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </div>
          <div class="text-h6 text-center" v-if="news[selectedNews].body">
            {{ news[selectedNews].body }}
          </div>
          <div class="text-center">
            <v-btn
              color="yellow"
              v-if="news[selectedNews].action"
              :loading="logApi.isLoading"
              x-large
              rounded
              class="text-h5"
              @click="clickAction(news[selectedNews])"
              >{{ news[selectedNews].action }}</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions
          v-if="news.length > 1"
          class="d-flex justify-space-around"
        >
          <div>
            <v-btn icon @click="previous()" :disabled="selectedNews == 0">
              <v-img
                :class="selectedNews == 0 ? 'disabled-item-image' : null"
                contain
                max-width="40"
                class="mx-auto"
                :src="require('@/assets/island/ArrowButtonPre.png')"
              ></v-img>
            </v-btn>
          </div>
          <div>
            <v-btn
              icon
              @click="next()"
              :disabled="selectedNews == news.length - 1"
            >
              <v-img
                :class="
                  selectedNews == news.length - 1 ? 'disabled-item-image' : null
                "
                contain
                max-width="40"
                class="mx-auto"
                :src="require('@/assets/island/ArrowButtonNex.png')"
              ></v-img>
            </v-btn>
          </div>
        </v-card-actions>
        <div class="bullet-points d-flex justify-center align-end pt-2">
          <div v-for="j in news.length" :key="j" @click="clickBullet(j)">
            <div
              class="bullet"
              :class="
                j - 1 == selectedNews ? 'bullet-border-blue' : 'bullet-border'
              "
            ></div>
          </div>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-title class="text-h5 teal--text text--darken-3 pt-0 pe-0">
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="
              () => {
                dialog = false;
              }
            "
          />
        </v-card-title>
        <v-card-text class="d-flex align-center justify-center mt-5 mb-10">
          {{ $t("string.noNews") }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import delay from "delay";
let audio = new Audio(require("@/assets/sounds/noti-1.wav"));
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  data: () => ({
    selectedNews: 0,
    dialog: false,
    setInterval: null,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    logApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    news: [],
    url: null,
  }),
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  created() {
    this.api.url =
      this.$api.servers.game + "/api/v1/" + this.$_getLocale() + "/game/news";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.news = resp.News;
      if (this.news.length != 0) {
        this.dialog = true;
      }
      //  this.api.isLoading = false;
      //  this.setInterval = setInterval(() => {
      //    this.selectedNews = this.selectedNews + 1;
      //    if (this.selectedNews == this.news.length) {
      //      this.selectedNews = 0;
      //    }
      //  }, 3000);
    };
    this.logApi.url =
      this.$api.servers.analysis +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/news/read";
    this.logApi.callbackReset = () => {
      this.logApi.isLoading = true;
      this.logApi.isError = false;
      this.logApi.error = null;
    };
    this.logApi.callbackError = (e) => {
      this.logApi.isLoading = false;
      this.logApi.isError = true;
      this.logApi.error = e;
    };
    this.logApi.callbackSuccess = () => {
      this.logApi.isLoading = false;
      window.location.assign(this.url);
    };
  },
  methods: {
    async fetch() {
      await delay(300);
      this.$api.fetch(this.api);
      audio.volume = this.settings.audio.sfx * this.settings.audio.master;
      audio.play();
    },
    async loadNews() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/news/visit";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.news = resp.News;
        this.dialog = true;
        audio.volume = this.settings.audio.sfx * this.settings.audio.master;
      };
      this.$api.fetch(this.api);
    },
    next() {
      this.selectedNews = this.selectedNews + 1;
      //clearInterval(this.setInterval);
    },
    previous() {
      this.selectedNews = this.selectedNews - 1;
      //clearInterval(this.setInterval);
    },
    clickBullet(j) {
      this.selectedNews = j - 1;
      // clearInterval(this.setInterval);
    },
    clickAction(item) {
      // clearInterval(this.setInterval);
      this.logApi.params = {
        newsId: item.id,
        mochiId: this.auth.Player.mochiId,
      };
      this.$api.fetch(this.logApi);
      this.url =
        process.env.VUE_APP_URL + "/" + this.$_getLocale() + item.actionUrl;
    },
  },
  beforeDestroy() {
    //clearInterval(this.setInterval);
  },
};
</script>
<style scoped>
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.bullet-points > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
}
.bullet-border-blue {
  background-color: #8b5629;
}
.bullet-border {
  background-color: #8b572956;
}

.image-with-halo {
  position: relative;
  display: inline-block;
}

.image-with-halo .v-img {
  display: block;
  border-radius: 50%;
}

.halo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  box-shadow: 0 0 20px #8b5629;
}
.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
